<template>
  <v-snackbar
    v-if="notification"
    outlined
    v-model="snackbar"
    :color="notification.type"
    timeout="3000"
  >
    {{ notification.text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "BottomNotification",
  data() {
    return {
      snackbar: false,
    };
  },
  methods: {
    showSnackbar() {
      this.snackbar = true;
    },
  },
  computed: {
    ...mapGetters({
      notification: "assembler/getNotification",
    }),
  },
  watch: {
    notification() {
      this.showSnackbar();
    },
  },
};
</script>
