import Vue from "vue";
import Axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // Will be updated with dotenv package.
  baseURL: process.env.VUE_APP_BACKEND_URL || "http://localhost/api",
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const axios = Axios.create(config);

let refreshed = false;

axios.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  async (error) => {
    const originalConfig = error.config;
    if (!error.response || !error.response.status) return Promise.reject(error);
    switch (error.response.status) {
      case 401:
        if (!refreshed) {
          try {
            refreshed = true;
            const res = await axios.get("/csrf-cookie");
            axios.defaults.headers["X-XSRF-TOKEN"] = res.data;
            await axios.post("/refresh-token");

            return axios(originalConfig);
          } catch (err) {
            refreshed = false;
            window.location.replace(
              `${process.env.VUE_APP_LOGIN_URL}/login?intendedUrl=${window.location.href}`
            );
          }
        }
        break;
      case 403:
      case 404:
      case 500:
        window.dispatchEvent(
          new CustomEvent("servererror", { detail: error.response.status, text: error })
        );
        break;
      default:
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;

export default axios;
