import axios from "../../plugins/axios";

const initialState = {
  locales: [],
};

const initialData = () => initialState;

const getters = {
  getList: (state) => state.locales,
};

const mutations = {
  setList(state, list) {
    state.locales = list;
  },
};

const actions = {
  async fetchList({ commit }) {
    const res = await axios.get("locales");
    commit("setList", res.data);
    return true;
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialData,
};
