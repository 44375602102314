import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import locales from "./modules/locales";
import assembler from "./modules/assembler";
import assessments from "./modules/assessments";
import assessmentLocalisations from "./modules/assessmentLocalisations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    locales,
    assembler,
    assessments,
    assessmentLocalisations,
  },
});
