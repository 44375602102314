import axios from "../../plugins/axios";

const initialState = {
  isAuthenticated: false,
};

const initialData = () => initialState;

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
};

const mutations = {
  setIsAuthenticated(state, status) {
    state.isAuthenticated = status;
  },
};

const actions = {
  async checkAuth({ dispatch }) {
    try {
      await dispatch("fetchCookie");
      const res = await axios.get("user");
      return res;
    } catch (err) {
      return Promise.reject(err.response ? err.response.data : err);
    }
  },
  async logout({ commit }) {
    await axios.get("logout");
    commit("setIsAuthenticated", false);
    return true;
  },
  async fetchCookie() {
    try {
      const res = await axios.get("/csrf-cookie");
      axios.defaults.headers.common["X-XSRF-TOKEN"] = res.data;
      return true;
    } catch (err) {
      return Promise.reject(err.response ? err.response.data : err);
    }
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialData,
};
