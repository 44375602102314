export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/",
    redirect: { name: "StreamsList" },
  },
  {
    path: "/streams",
    component: () => import("../views/StreamsContainer.vue"),
    children: [
      {
        path: "/",
        name: "StreamsList",
        component: () => import("../views/StreamsList.vue"),
      },
      {
        path: ":streamId/localisation/:localeId",
        name: "StreamView",
        component: () => import("../views/StreamView.vue"),
      },
      {
        path: ":streamId/localisation/:localeId/preview",
        name: "StreamPreview",
        component: () => import("../views/StreamPreview.vue"),
      },
    ],
  },
  {
    path: "/element-preview/:type/:id",
    name: "ElementPreview",
    component: () => import("../views/ElementPreview.vue"),
  },
  {
    path: "/assessments",
    component: () => import("../views/assessments/AssessmentContainer.vue"),
    children: [
      {
        path: "/",
        name: "AssessmentList",
        component: () => import("../views/assessments/AssessmentList.vue"),
      },
      {
        path: "/:assessmentId/localisation/:localeId",
        name: "AssessmentView",
        component: () => import("../views/assessments/AssessmentView.vue"),
      },
    ],
  },
  {
    path: "/assemblies",
    component: () => import("../views/assemblies/AssemblyContainer.vue"),
    children: [
      {
        path: "/",
        name: "AssemblyList",
        component: () => import("../views/assemblies/AssemblyList.vue"),
      },
      {
        path: "/localisations/:localisationId",
        name: "LocalisationView",
        component: () => import("../views/assemblies/LocalisationView.vue"),
      },
    ],
  },
];
