import axios from "../../plugins/axios";

const initialState = {
  localisation: null,
  loading: true,
  units: [],
};

const initialData = () => initialState;

const getters = {
  getLoading: (state) => state.loading,
  getLocalisation: (state) => state.localisation,
  getUnits: (state) => state.units,
};

const mutations = {
  setLoading(state, status) {
    state.loading = status;
  },
  setLocalisation(state, localisation) {
    state.localisation = localisation;
  },
  setUnits(state, units) {
    state.units = units;
  },
};

const actions = {
  async fetchLocalisation({ commit }, id) {
    commit("setLoading", true);
    const res = await axios.get(`assessments-localisations/${id}`);
    commit("setLocalisation", res.data);
    commit("setLoading", false);
  },
  async refreshLocalisation({ state, commit }) {
    commit("setLoading", true);

    const res = await axios.get(`assessments-localisations/${state.localisation.id}`);
    commit("setLocalisation", res.data);
    commit("setLoading", false);
  },
  async updateLocalisation({ commit, dispatch }, payload) {
    try {
      commit("setLoading", true);
      await axios.patch(`assessments-localisations/${payload.id}`, payload);
      dispatch("refreshLocalisation");
    } catch (err) {
      console.log(err);
    }
  },
  async fetchUnits({ commit }) {
    commit("setLoading", true);
    const res = await axios.get("assessments-units");
    commit("setUnits", res.data.assessmentUnits);
    commit("setLoading", false);
  },
  async addUnit({ commit, dispatch }, payload) {
    commit("setLoading", true);
    await axios.post("assessments-units", payload);
    dispatch("refreshLocalisation");
  },
  async moveUnit({ commit, dispatch }, payload) {
    try {
      commit("setLoading", true);
      await axios.put(`assessments-units/${payload.id}/${payload.action}`);
      dispatch("refreshLocalisation");
    } catch (err) {
      console.log(err);
    }
  },
  async deleteUnit({ commit, dispatch }, id) {
    try {
      commit("setLoading", true);
      await axios.delete(`assessments-units/${id}`);
      dispatch("refreshLocalisation");
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialData,
};
