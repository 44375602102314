<template>
  <v-app v-if="!loading">
    <app-menu />
    <v-main>
      <v-container fluid>
        <transition name="fade">
          <router-view />
        </transition>
      </v-container>
    </v-main>
    <bottom-notification />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

import AppMenu from "@/components/AppMenu.vue";
import BottomNotification from "./components/BottomNotification.vue";

export default {
  name: "App",
  components: { AppMenu, BottomNotification },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await this.checkAuth();
    this.loading = false;
  },
  methods: {
    ...mapActions({ checkAuth: "user/checkAuth" }),
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
