<template>
  <v-card>
    <v-navigation-drawer mini-variant permanent app expand-on-hover>
      <v-list dense nav>
        <v-list-item-group color="primary">
          <v-list-item v-for="item in menu" :key="item.title" :to="{ name: item.routeName }" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item key="logout" link @click="attemptLogout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "AppMenu",
  data() {
    return {
      menu: [
        {
          title: "Streams",
          routeName: "StreamsList",
          icon: "mdi-format-list-text",
        },
        {
          title: "Assessments",
          routeName: "AssessmentList",
          icon: "mdi-check-decagram-outline",
        },
        {
          title: "Assemblies",
          routeName: "AssemblyList",
          icon: "mdi-format-list-group",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      logout: "user/logout",
    }),
    async attemptLogout() {
      await this.logout();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
