import axios from "../../plugins/axios";

const initialState = {
  assessmentList: [],
  listLoading: true,
};

const initialData = () => initialState;

const getters = {
  getListLoading: (state) => state.listLoading,
  getList: (state) => state.assessmentList,
};

const mutations = {
  setListLoading(state, status) {
    state.listLoading = status;
  },
  setList(state, list) {
    state.assessmentList = list;
  },
};

const actions = {
  async fetchList({ commit }) {
    commit("setListLoading", true);
    //     illustration: "https://lifeed-cms.s3.eu-west-1.amazonaws.com/Man_Cat_Petting_Hug_Love_V3_28644e39e1.svg",
    const res = await axios.get("assessments");
    commit("setList", res.data);
    commit("setListLoading", false);
    return true;
  },
  async createAssessment({ dispatch, commit }, formData) {
    commit("setListLoading", true);
    const assessmentData = {
      name: formData.name,
      imgUrl: formData.illustration,
    };
    await axios.post("assessments", assessmentData);
    dispatch("fetchList");
  },
  async updateAssessment({ dispatch, commit }, payload) {
    commit("setListLoading", true);
    const assessmentData = {
      name: payload.formData.name,
      imgUrl: payload.formData.imgUrl,
    };
    await axios.put(`assessments/${payload.assessmentId}`, assessmentData);
    dispatch("fetchList");
  },
  async createLocalisation({ commit }, payload) {
    commit("setListLoading", true);
    return axios.post("assessments-localisations", payload);
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialData,
};
