import axios from "../../plugins/axios";

const initialState = {
  streamsLoading: true,
  streams: [],
  currentStream: null,
  localisationLoading: false,
  localisation: null,
  elements: null,
  elementTypes: [],
  aleTypes: [],
  elementModal: false,
  availableElements: [],
  tags: [],
  elementsLoading: false,
  selectedGroup: null,
  notification: null,
  localisationTags: [],
  localisationUniqueTags: [],
  canEditTags: false,
};

const initialData = () => initialState;

const getters = {
  getStreamsLoading: (state) => state.streamsLoading,
  getStreams: (state) => state.streams,
  getStream: (state) => state.currentStream,
  getLocalisationLoading: (state) => state.localisationLoading,
  getLocalisation: (state) => state.localisation,
  getElements: (state) => state.elements,
  getElementTypes: (state) => state.elementTypes,
  getAleTypes: (state) => state.aleTypes,
  getElementModal: (state) => state.elementModal,
  getAvailableElements: (state) => state.availableElements,
  getTags: (state) => state.tags,
  getSelectedGroup: (state) => state.selectedGroup,
  getNotification: (state) => state.notification,
  getLocalisationTags: (state) => state.localisationTags,
  getUniqueTags: (state) => state.localisationUniqueTags,
  getCanEditTags: (state) => state.canEditTags,
};

const mutations = {
  setLocalisationLoading(state, status) {
    state.localisationLoading = status;
  },
  setStreamsLoading(state, status) {
    state.streamsLoading = status;
  },
  setStreams(state, streams) {
    state.streams = streams;
  },
  setLocalisation(state, localisation) {
    state.localisation = localisation;
  },
  setElements(state, elements) {
    state.elements = elements;
  },
  setElementModal(state, modalState) {
    state.elementModal = modalState;
  },
  setAvailableElements(state, elements) {
    state.availableElements = elements;
  },
  setTags(state, tags) {
    state.tags = tags;
  },
  setElementTypes(state, types) {
    state.elementTypes = types;
  },
  setAleTypes(state, types) {
    state.aleTypes = types;
  },
  setSelectedGroup(state, id) {
    state.selectedGroup = id;
  },
  setNotification(state, notification) {
    state.notification = notification;
  },
  setLocalisationTags(state, tags) {
    state.localisationTags = tags;
  },
  setLocalisationUniqueTags(state, elements) {
    let allElements = [];
    const tags = [];
    Object.values(elements).forEach((els) => {
      allElements = allElements.concat(els);
    });
    allElements.forEach((element) => {
      element.tags.forEach((tag) => {
        if (tags.findIndex((t) => t.id === tag.id) === -1) {
          tags.push(tag);
        }
      });
    });
    state.localisationUniqueTags = tags;
  },
  setCanEditTags(state, tags) {
    state.canEditTags = tags.length === 0;
  },
  resetLocalisationData(state) {
    state.localisation = null;
    state.elementTypes = [];
    state.aleTypes = [];
    state.localisationTags = [];
    state.localisationUniqueTags = [];
    state.canEditTags = false;
  },
};

const actions = {
  async fetchStreams({ commit }) {
    try {
      commit("setStreamsLoading", true);
      const res = await axios.get("localisations");
      commit("setStreamsLoading", false);
      commit("setStreams", res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async fetchLocalisation({ commit }, id) {
    try {
      commit("resetLocalisationData");
      commit("setLocalisationLoading", true);
      const res = await axios.get(`localisations/${id}?preview=1`);
      commit("setLocalisationLoading", false);
      commit("setElements", res.data.elements);
      commit("setLocalisation", res.data.localisation);
      commit("setElementTypes", res.data.elementTypes);
      commit("setAleTypes", res.data.aleTypes);
      commit("setLocalisationUniqueTags", res.data.elements);
      commit("setLocalisationTags", res.data.tags);
      commit("setCanEditTags", res.data.tags);
    } catch (err) {
      console.log(err);
    }
  },
  async refreshLocalisation({ state, commit }) {
    try {
      commit("setLocalisationLoading", true);
      const res = await axios.get(`localisations/${state.localisation.id}?preview=1`);
      commit("setLocalisationLoading", false);
      commit("setElements", res.data.elements);
      commit("setLocalisation", res.data.localisation);
      commit("setElementTypes", res.data.elementTypes);
      commit("setAleTypes", res.data.aleTypes);
      commit("setLocalisationUniqueTags", res.data.elements);
      commit("setLocalisationTags", res.data.tags);
      commit("setCanEditTags", res.data.tags);
    } catch (err) {
      console.log(err);
    }
  },
  async updateLocalisation({ commit, dispatch }, payload) {
    try {
      commit("setLocalisationLoading", true);
      await axios.patch(`localisations/${payload.id}`, payload);
      commit("setNotification", { type: "success", text: "Localisation updated succesfully" });
      dispatch("refreshLocalisation");
    } catch (err) {
      console.log(err);
    }
  },
  async addLocalisation(context, payload) {
    try {
      const res = await axios.post("localisations", payload);
      return res.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async addAle({ commit, dispatch }, payload) {
    try {
      const data = { ...payload, type: payload.type ? payload.type : 2 };
      await axios.post("ales", data);
      commit("setNotification", { type: "success", text: "Ale added succesfully" });
      dispatch("refreshLocalisation");
    } catch (err) {
      console.log(err);
    }
  },
  async updateAleType({ commit }, payload) {
    try {
      await axios.patch(`ales/${payload.id}`, payload);
      commit("setNotification", { type: "success", text: "Ale updated succesfully" });
    } catch (err) {
      console.log(err);
    }
  },
  async moveEntity({ commit, dispatch }, payload) {
    try {
      await axios.put(`${payload.entity}s/${payload.id}/${payload.action}`);
      commit("setNotification", { type: "success", text: `${payload.entity} moved succesfully` });
      dispatch("refreshLocalisation");
    } catch (err) {
      console.log(err);
    }
  },
  async deleteAle({ commit, dispatch }, id) {
    try {
      await axios.delete(`ales/${id}`);
      dispatch("refreshLocalisation");
      commit("setNotification", { type: "success", text: "Ale removed succesfully" });
    } catch (err) {
      console.log(err);
    }
  },
  async addGroup({ commit, dispatch }, aleId) {
    try {
      await axios.post("groups", { aleId });
      dispatch("refreshLocalisation");
      commit("setNotification", { type: "success", text: "Group added succesfully" });
    } catch (err) {
      console.log(err);
    }
  },
  async deleteGroup({ commit, dispatch }, id) {
    try {
      await axios.delete(`groups/${id}`);
      dispatch("refreshLocalisation");
      commit("setNotification", { type: "success", text: "Group removed succesfully" });
    } catch (err) {
      console.log(err);
    }
  },
  async fetchElements({ commit }, payload) {
    try {
      if (!payload.type) {
        commit("setAvailableElements", []);
        commit("setTags", []);
        return;
      }

      let url = `elements?type=${payload.type}`;

      if (payload.tags) {
        url += `&tags=${payload.tags.join(",")}`;
      }

      commit("setLocalisationLoading", true);
      const res = await axios.get(url);
      commit("setLocalisationLoading", false);
      commit("setAvailableElements", res.data[payload.type]);
      commit("setTags", res.data.tags);
    } catch (err) {
      console.log(err);
    }
  },
  async addElement({ commit, dispatch }, payload) {
    try {
      commit("setLocalisationLoading", true);
      await axios.post("elements", {
        groupId: payload.groupId,
        relatedType: payload.type,
        relatedId: payload.id,
      });
      dispatch("refreshLocalisation");
      commit("setNotification", { type: "success", text: "Element added succesfully" });
    } catch (err) {
      console.log(err);
    }
  },
  async deleteElement({ commit, dispatch }, id) {
    try {
      commit("setLocalisationLoading", true);
      await axios.delete(`elements/${id}`);
      dispatch("refreshLocalisation");
      commit("setNotification", { type: "success", text: "Element removed succesfully" });
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialData,
};
